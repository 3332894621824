<template>
    <a-drawer
        title="Добавить секцию"
        placement="right"
        :width="isMobile ? '100%' : 1000"
        :visible="visible"
        :zIndex="999999"
        :after-visible-change="afterVisibleChange"
        @close="visible = false">
        <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules">
            <a-form-model-item 
                ref="name" 
                label="Название" 
                prop="name">
                <a-input v-model="form.name" size="large" />
            </a-form-model-item>
            <a-form-model-item 
                ref="sort" 
                label="Позиция" 
                prop="sort">
                <a-input-number v-model="form.sort" style="width: 300px;" size="large" :min="0" :max="100000" />
            </a-form-model-item>
            <a-form-model-item 
                ref="show_on_main_page" 
                label="Показать на главной" 
                prop="show_on_main_page">
                <a-switch v-model="form.show_on_main_page" />
            </a-form-model-item>
            <a-form-model-item 
                ref="code" 
                label="Код" 
                prop="code">
                <a-input v-model="form.code" size="large" />
            </a-form-model-item>
            <a-form-model-item 
                ref="random_html" 
                label="Описание" 
                prop="random_html">
                <component
                    :is="ckEditor"
                    :key="edit || visible"
                    v-model="form.random_html" />
            </a-form-model-item>
            <a-form-model-item>
                <a-button type="primary" size="large" :loading="loading" @click="onSubmit()">
                    Сохранить
                </a-button>
                <template v-if="edit">
                    <a-button type="danger" size="large" class="ml-2" :loading="dangerLoading" @click="deleteHandler()">
                        Удалить
                    </a-button>
                </template>
            </a-form-model-item>
        </a-form-model>
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
    props: {
        fUpdChapterList: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        ckEditor() {
            if(this.visible)
                return () => import('@apps/CKEditor')
            else
                return null
        }
    },
    data() {
        return {
            visible: false,
            loading: false,
            edit: false,
            dangerLoading: false,
            form: {
                name: '',
                random_html: '',
                use_in_wiki: true,
                show_on_main_page: true,
                sort: 500,
                code: ''
            },
            rules: {
                name: [
                    { required: true, message: this.$t('field_required'), trigger: 'blur' }
                ],
                random_html: [
                    { required: true, message: this.$t('field_required'), trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        afterVisibleChange(vis) {
            if(!vis) {
                this.edit = false
                this.form = {
                    name: '',
                    random_html: '',
                    use_in_wiki: true,
                    show_on_main_page: true,
                    sort: 500,
                    code: ''
                }
            }
        },
        async deleteHandler() {
            try {
                this.dangerLoading = true
                await this.$http.delete(`/wiki/sections/${this.form.id}/`)
                this.visible = false
                this.fUpdChapterList()
                this.$message.info('Секция удалена')
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка удаления')
            } finally {
                this.dangerLoading = false
            }
        },
        onSubmit() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    const formData = {...this.form}

                    if(this.edit) {
                        try {
                            this.loading = true
                            const { data } = await this.$http.put(`/wiki/sections/${formData.id}/`, formData)
                            if(data) {
                                this.visible = false
                                this.fUpdChapterList()
                                this.$message.info('Секция обновлена')
                            }
                        } catch(e) {
                            console.log(e)
                            this.$message.error('Ошибка создания')
                        } finally {
                            this.loading = false
                        }
                    } else {
                        try {
                            this.loading = true
                            const { data } = await this.$http.post('/wiki/sections/', formData)
                            if(data) {
                                this.visible = false
                                this.fUpdChapterList()
                                this.$message.info('Секция создана')
                            }
                        } catch(e) {
                            console.log(e)
                            this.$message.error('Ошибка создания')
                        } finally {
                            this.loading = false
                        }
                    }
                } else {
                    return false;
                }
            })
        },
    },
    mounted() {
        eventBus.$on('open_section_drawer', (value = null) => {
            if(value) {
                this.edit = true
                const editData = {...value}
                this.form = editData
            }
            this.visible = true
        })
    },
    beforeDestroy() {
        eventBus.$off('open_section_drawer')
    }
}
</script>